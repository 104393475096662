import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"

const banner_title  = ['and Commitment Statement ', 3000]



const accessibilityPolicy = () => { 

    return (
<Layout>
<Seo title="Our Accessibility Policy" />
<Banner className = "service" title = {banner_title} sticky_first = "Accessibility Policy " sticky_second  = "" />

<section className = "full_details">
<div className="container">
<p>Estorecompany LLC “operator of site (Esotrecompany.com) is committed to interacting, communicating and 
    providing Digital goods such as e-books and services to all customers and members of the public that are
     based on the full inclusion of all persons with disabilities. We recognize people with disabilities may 
     have different needs and we will in accordance with government guidelines, support the identification,
      removal and prevention of barriers for persons with disabilities. If you wish to provide Estorecompany.com 
      with feedback regarding the manner in which we provided our service, please choose one of the feedback options 
      below that best addresses your needs. 

      <h4>send us an email:</h4> <a href ="mailto:Info@estorecompany.com">Info@estorecompany.com</a>
 </p>

</div>

</section>


</Layout>


    )


}


export default accessibilityPolicy